
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  DailyGridDTO, DailyGridAverageDTO, DailyGridProgramDTO,
  DailyGridSecondaryTvNetworkDTO, DailyGridAudienceSeriesItemDTO,
  DailyGridSecondaryTvNetworkAverageDTO,
} from '@/data/dto/daily-grid.dto';
import { ViewersProjectionBaseType, formatNumberToStringWithSymbol, numberToBrString } from "@/utils/number.util";
import DataFormatControlsContainer from '@/components/DataFormatControlsContainer.vue';
import { TargetBaseType, generateViewersProjectionLabel } from '@/utils/viewers-projection.util';

export type DailyGridTvNetworkAudienceData = {
  name: string;
  color: string;
  audienceSeries: DailyGridAudienceSeriesItemDTO[];
  isMain?: boolean;
  showViewersProjection?: boolean;
}

export type DailyGridTvNetworkAverageData = {
  name: string;
  color: string;
  audience: number;
  share: number;
  isMain?: boolean;
  viewersProjection?: number;
}

@Component({
  components: {
    DataFormatControlsContainer,
  }
})
export default class AudienceComparisonGrid extends Vue {
  @Prop() private grid!: DailyGridDTO;
  @Prop() private average?: DailyGridAverageDTO;
  @Prop({ default: false }) private hideIcons!: boolean;
  @Prop({ default: false }) private showEndTime!: boolean;
  @Prop({ default: false }) private removableColumns!: boolean;
  @Prop({ default: 0 }) private defaultDecimalPlaces!: number;
  @Prop({ default: 'residences' }) private targetBaseType!: TargetBaseType;
  @Prop({ default: 'Total' }) private selectedConnectedTvSumTypeLabel!: string;

  decimalPlaces = this.defaultDecimalPlaces;
  viewersProjectionBase: ViewersProjectionBaseType = {
    value: 1000000,
    label: 'Milhão',
    baseSymbol: 'M',
    decimalPlaces: 1,
  };

  get allTvNetworkAudienceList(): DailyGridTvNetworkAudienceData[] {
    const mainTvNetworkAudienceData = {
      name: this.grid.mainTvNetwork.name,
      color: this.grid.mainTvNetwork.color,
      audienceSeries: this.grid.mainTvNetwork.programs.map((program) => program.audienceData),
      isMain: true,
      showViewersProjection: this.grid.mainTvNetwork.showViewersProjection,
    };
    return [
      mainTvNetworkAudienceData,
      ...this.grid.secondaryTvNetworks,
    ];
  }

  get allTvAverages(): DailyGridTvNetworkAverageData[] {
    if (!this.average) {
      return [];
    }
    const mainTvNetworkAverageData = {
      name: this.average?.mainTvNetwork.name,
      color: this.average?.mainTvNetwork.color,
      audience: this.average?.mainTvNetwork.audience,
      share: this.average?.mainTvNetwork.share,
      isMain: true,
      viewersProjection: this.average?.mainTvNetwork.viewersProjection,
    };
    return [
      mainTvNetworkAverageData,
      ...(this.average?.secondaryTvNetworks || []),
    ];
  }

  get showViewersProjectionBaseController(): boolean {
    return this.allTvNetworkAudienceList.some(({ showViewersProjection }) => showViewersProjection);
  }

  get viewersProjectionLabel(): string {
    return `AUD ${generateViewersProjectionLabel(this.targetBaseType)}`;
  }

  formattedDecimalPlaces(n: number, decimalPlaces = this.decimalPlaces): number | string {
    if (!n) return '-';
    return numberToBrString(n, decimalPlaces);
  }

  formattedViewersProjection(numberOfViewers: number, base: ViewersProjectionBaseType = this.viewersProjectionBase ): string {
    return formatNumberToStringWithSymbol(numberOfViewers, base);
  }

  getTimeLabel(time:string): string {
    return time ? time.slice(0,5) : '';
  }

  checkLineVisibilityByProgram(program: DailyGridProgramDTO): boolean {
    const parent = this.grid.mainTvNetwork.programs.filter(p => p.id === program.childOf);
    return !program.childOf  || (!!parent && !!parent.length && !!parent[0].showChildren);
  }

  getTvAudienceByProgram(index: number, tv: DailyGridTvNetworkAudienceData): DailyGridAudienceSeriesItemDTO | null {
    if(!tv || !Number.isInteger(index)) return null;
    return tv.audienceSeries?.[index];
  }

  getTvAverage(tv:DailyGridSecondaryTvNetworkDTO): DailyGridSecondaryTvNetworkAverageDTO | null {
    if (!this.allTvAverages?.length) return null;
    return this.allTvAverages.filter(item => item.name === tv.name)[0];
  }

  getBackgroundColor(audienceData: DailyGridAudienceSeriesItemDTO, dataType: 'audience' | 'share' = 'audience', hideIcons = this.hideIcons): string {
    if (hideIcons || !audienceData) return 'transparent';
    try {
      const color = audienceData[dataType].status?.color || 'transparent';
      return color;
    } catch {
      return 'transparent';
    }
  }

  toggleChildVisibility(program: DailyGridProgramDTO): void {
    program.showChildren = !program.showChildren;
  }

  hasChildren(program:DailyGridProgramDTO): boolean {
    if (program.childOf) return false;
    return !!this.grid.mainTvNetwork.programs.filter(p => p.childOf === program.id).length;
  }

  removeTvData(index: number): void {
    this.grid.secondaryTvNetworks.splice(index, 1);
  }

  onViewersProjectionBaseChange(base: ViewersProjectionBaseType): void {
    this.viewersProjectionBase = base;
  }

  onDecimalPlacesChange(decimalPlaces: number): void {
    this.decimalPlaces = decimalPlaces;
  }
}
